<template>
  <div>
    <div class="mb-1">
      <!-- <b-form-group
        label="Profesional"
        label-cols="3"
        label-cols-sm="2"
        label-cols-lg="2"
        label-cols-xl="2"
        label-for="support"
        label-class="middle"
        class="m-0"
      >
        <b-row>
          <b-col>{{
            professional
              ? professional.first_name + " " + professional.last_name
              : ""
          }}</b-col>
          <b-col
            ><b-button
              id="support"
              size="sm"
              @click="$bvModal.show('modal-user')"
              >Seleccionar</b-button
            ></b-col
          >
        </b-row>
      </b-form-group> -->

      <b-form-group
        label="Tipo de Identificador:"
        label-cols="3"
        :label-for="`input-identifier_type-${support.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-select
          :id="`input-identifier_type-${support.id}`"
          :disabled="support.professional ? true : false"
          :options="identifierTypes"
          value-field="id"
          text-field="identifier_name"
          v-model="support.identifier_type"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        label="Identificador:"
        label-cols="3"
        :label-for="`textarea-identifier-${support.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-input
          :id="`textarea-identifier-${support.id}`"
          v-model="support.identifier"
          :disabled="support.professional ? true : false"
          class="m-0"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Nombre:"
        label-cols="3"
        :label-for="`textarea-first-name-${support.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-input
          :id="`textarea-first-name-${support.id}`"
          v-model="$v.support.first_name.$model"
          :state="validateState('first_name')"
          :disabled="support.professional ? true : false"
          class="m-0"
          aria-describedby="input-first-name-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-first-name-live-feedback"
          >Este campo es obligatorio y debe tener al menos 3
          caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Apellidos:"
        label-cols="3"
        :label-for="`textarea-last-name-${support.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-input
          :id="`textarea-last-name-${support.id}`"
          v-model="$v.support.last_name.$model"
          :state="validateState('last_name')"
          :disabled="support.professional ? true : false"
          class="m-0"
          aria-describedby="input-last-name-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-last-name-live-feedback"
          >Este campo es obligatorio y debe tener al menos 3
          caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Correo:"
        label-cols="3"
        :label-for="`textarea-email-${support.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-input
          :id="`textarea-email-${support.id}`"
          v-model="support.email"
          :disabled="support.professional ? true : false"
          class="m-0 mb-3"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        v-if="support.professional ? true : false"
        label="Puede Editar el Acta:"
        label-cols="3"
        :label-for="`textarea-can-edit-${support.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-checkbox
          :id="`textarea-can-edit-${support.id}`"
          v-model="support.can_edit"
          class="m-0"
        >
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Asistió:"
        label-cols="3"
        :label-for="`textarea-assisted-${support.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-checkbox
          :id="`textarea-assisted-${support.id}`"
          v-model="support.assisted"
          class="m-0"
        >
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Cargo:"
        label-cols="3"
        :label-for="`textarea-responsibles-${support.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-input
          :id="`textarea-responsibles-${support.id}`"
          v-model="support.occupation"
          class="m-0"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Otra Información:"
        label-cols="3"
        :label-for="`textarea-other-data-${support.id}`"
        label-class="middle"
        class="m-0 mb-3"
      >
        <b-form-input
          :id="`textarea-other-data-${support.id}`"
          v-model="support.other_data"
          class="m-0"
        ></b-form-input>
      </b-form-group>
      <!-- <b-form-group
        label="Momento:"
        label-cols="3"
        label-cols-sm="2"
        label-cols-lg="2"
        label-cols-xl="2"
        :label-for="`input-date-${support.id}`"
        label-class="middle"
        class="m-0"
      >
        <b-form-select
          :id="`input-date-${support.id}`"
          :options="SupportTypes"
          value-field="id"
          text-field="value"
          v-model="support.moment"
        ></b-form-select>
      </b-form-group> -->
      <div class="row">
        <div
          v-if="show_delete_button && !isNaN(support.id)"
          class="col"
          style="text-align: left"
        >
          <b-button class="mr-1" size="sm" variant="danger">Eliminar</b-button>
        </div>
        <div class="col" style="text-align: right">
          <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
        </div>
      </div>
    </div>
    <!-- User Modal -->
    <!-- <b-modal
      id="modal-user"
      title="Seleccionar Profesional"
      size="lg"
      hide-footer
    >
      <UserList
        :filter_by_professor="true"
        :filter_by_student="false"
        @selected_users="slotSelectedUsers"
      ></UserList>
    </b-modal> -->
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "SupportForm",
  mixins: [validationMixin],
  components: {
    // UserList: () => import("@/components/reusable/UserList"),
  },
  props: {
    Support: {
      type: Object,
    },
    act_id: {
      type: Number,
      required: true,
    },
    identifierTypes: {
      type: Array,
      required: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    SupportTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      support: {
        id: this.Support ? this.Support.id : generateUniqueId(),
        act: this.Support ? this.Support.act : this.act_id,
        professional: this.Support ? this.Support.professional : null,
        moment: this.Support ? this.Support.moment : 1,
        identifier_type: this.Support ? this.Support.identifier_type : null,
        occupation: this.Support ? this.Support.occupation : "",
        first_name: this.Support ? this.Support.first_name : "",
        last_name: this.Support ? this.Support.last_name : "",
        identifier: this.Support ? this.Support.identifier : "",
        can_edit: this.Support ? this.Support.can_edit : false,
        assisted: this.Support ? this.Support.assisted : false,
        email: this.Support ? this.Support.email : "",
        other_data: this.Support ? this.Support.other_data : "",
      },
      professional: null,
    };
  },
  validations: {
    support: {
      first_name: {
        required,
        minLength: minLength(3),
      },
      last_name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    aspect() {
      return this.Aspects.find((x) => x.id == this.support.aspect);
    },
    // fulfillment() {
    //   if (!this.support.is_fulfilled) return "";
    //   const type = this.FulfillTypes.find(
    //     (x) => x.id == this.support.is_fulfilled
    //   );
    //   return type.value;
    // },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.support[key];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.support.$touch();
      if (this.$v.support.$anyError) {
        return;
      }
      if (isNaN(this.support.id)) this.createSupport();
      else this.updateSupport();
    },
    createSupport() {
      this.$restful.Post(`/cideu/support/`, this.support).then((response) => {
        this.$emit("created", response);
        this.support = response;
        toast("Asistente creado.");
      });
    },
    updateSupport() {
      this.$restful
        .Put(`/cideu/support/${this.support.id}/`, this.support)
        .then((response) => {
          this.$emit("updated", response);
          this.support = response;
          toast("Asistente actualizado.");
        });
    },
    // slotSelectedUsers(users) {
    //   if (users.length > 0) {
    //     this.support.professional = users[0].id;
    //     this.professional = users[0];
    //   }
    //   this.$bvModal.hide("modal-user");
    // },
  },
  created() {
    // if (this.support.professional) {
    //   this.$restful
    //     .Get(`/authentication/user/${this.support.professional}/`)
    //     .then((response) => {
    //       this.professional = response;
    //     });
    // }
  },
};
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.item-text {
  text-align: justify;
  padding: 0.1em 1em;
  width: 95%;
}
.row-first {
  width: 20%;
  font-weight: bold;
}
.row-second {
  width: 80%;
  text-align: justify;
}
.support {
  border: 1px dashed var(--secondary-color);
}
.middle {
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
}
</style>